@import '../../../styles/customMediaQueries.css';
@import '../../../styles/marketplaceDefaults.css';

.contactSection {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: 0 26px;
  width: 100%;
  @media (--viewportMedium) {
    padding: 0;
    margin-top: 30px;
  }
}
.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 12px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}
.field label {
  color: var(--colorWhite);
}

.textarea {
  margin-top: 12px;
}
.textarea label {
  color: var(--colorWhite);
}
.contactButton {
  background-color: var(--marketplaceColor);
  margin: 50px 0;
  &:hover {
    background-color: var(--marketplaceColorDark);
    cursor: not-allowed;
  }
  &:focus {
    background-color: var(--marketplaceColorDark);
  }
}
.acceptPrivacy {
  padding-top: 8px;
  @media (--viewportMedium) {
    padding-top: 16px;
  }
}
.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  composes: h5 from global;

  color: var(--colorGrey700);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  composes: h5 from global;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey40);
  display: inline-block;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}
.saveForLaterUseLegalInfo a {
  color: var(--colorWhite);
  &:hover {
    text-decoration: none;
  }
}
